var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-image",
    staticStyle: {
      "background-image": "url('https://source.unsplash.com/1920x1280/?forest')"
    }
  }, [_c('div', {
    staticClass: "bg-black-25"
  }, [_c('div', {
    staticClass: "content content-full"
  }, [_c('div', {
    staticClass: "py-5 text-center"
  }, [_c('h1', {
    staticClass: "font-w700 my-2 text-white"
  }, [_vm._v(_vm._s(_vm.entity.name))]), _c('h2', {
    staticClass: "h4 font-w700 text-white-75"
  }, [_vm._v(_vm._s(_vm.entity.companyNumber))]), _c('router-link', {
    staticClass: "btn btn-hero-primary",
    attrs: {
      "to": {
        name: 'entity-edit',
        params: {
          id: _vm.entity._id
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-pencil mr-1"
  }), _vm._v(" Edit Entity ")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }